import React from 'react';
import ScheduleTable from './components/ScheduleTable';

function App() {
  return (
    <div className="App">
      <ScheduleTable />
    </div>
  );
}

export default App;
import React, { useState, useRef, useEffect } from 'react';
import { Edit, X, RotateCcw, Grid, Rows, Settings } from 'lucide-react';
import WelcomeSection from './WelcomeSection';
import DownloadAsImage from './DownloadAsImage';

const tablePresets = [
  { name: 'Classic', class: 'bg-white text-gray-800' },
  { name: 'Striped', class: 'bg-white text-gray-800 [&_tr:nth-child(even)]:bg-gray-100' },
  { name: 'Dark', class: 'bg-gray-900 text-white [&_th]:bg-gray-800 [&_td]:border-gray-700 [&_th]:border-gray-700' },
];

const colorOptions = [
  { name: 'Default', class: 'bg-[#1f5768]' },
  { name: 'Blue', class: 'bg-blue-600' },
  { name: 'Green', class: 'bg-green-600' },
  { name: 'Red', class: 'bg-red-600' },
  { name: 'Purple', class: 'bg-purple-600' },
  { name: 'Orange', class: 'bg-orange-600' },
];

export default function ScheduleTable() {
  const [title, setTitle] = useState('My Class Schedule');
  const [scheduleData, setScheduleData] = useState('');
  const [parsedData, setParsedData] = useState([]);
  const [activeDays, setActiveDays] = useState([]);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isTitleModalOpen, setIsTitleModalOpen] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [showInstructions, setShowInstructions] = useState(true);
  const [orientation, setOrientation] = useState('portrait');
  const [selectedPreset, setSelectedPreset] = useState(tablePresets[0]);
  const [selectedColor, setSelectedColor] = useState(colorOptions[0]);
  const [isRowBased, setIsRowBased] = useState(true);
  const [isCustomizeModalOpen, setIsCustomizeModalOpen] = useState(false);
  const tableRef = useRef(null);

  const parseScheduleData = (data) => {
    if (!data) {
      console.error('No data provided for parsing.');
      setParsedData([]);
      setActiveDays([]);
      return;
    }

    const lines = data.split('\n').slice(1); // Skip header
    const parsed = lines.map(line => {
      const parts = line.split('\t');
      if (parts.length < 5) {
        console.error('Line does not have enough data:', line);
        return null;
      }

      const [code, name, sec, room, time] = parts;
      return { code, name, sec, room, time };
    }).filter(Boolean);

    const schedule = {};
    const activeDaysSet = new Set();

    parsed.forEach(course => {
      const timeParts = course.time.split(':');
      if (timeParts.length < 2) {
        console.error('Invalid time format:', course.time);
        return;
      }

      const days = timeParts[0];
      const timeRange = timeParts.slice(1).join(':');
      const [startTime, endTime] = timeRange.split('-').map(t => t.trim());

      const convertTo12Hour = (time) => {
        if (!time) return '';
        const [hours, minutes] = time.split(':');
        if (!hours || !minutes) return time;

        let period = 'AM';
        let hour = parseInt(hours, 10);
        if (hour >= 12) {
          period = 'PM';
          if (hour > 12) hour -= 12;
        }
        if (hour === 0) hour = 12;
        return `${hour}:${minutes} ${period}`;
      };

      const timeSlot = `${convertTo12Hour(startTime)} - ${convertTo12Hour(endTime)}`;

      const dayMap = {
        'S': 'Sunday',
        'T': 'Tuesday',
        'A': 'Saturday',
        'R': 'Thursday',
        'M': 'Monday',
        'W': 'Wednesday'
      };

      days.split('').forEach(day => {
        const dayKey = dayMap[day];
        if (!schedule[timeSlot]) {
          schedule[timeSlot] = {
            time: timeSlot,
            startTime: startTime || '',
            Sunday: '',
            Monday: '',
            Tuesday: '',
            Wednesday: '',
            Thursday: '',
            Saturday: '',
          };
        }
        if (dayKey) {
          schedule[timeSlot][dayKey] = `${course.code}<div class="text-xs">Room: ${course.room}</div>`;
          activeDaysSet.add(dayKey);
        }
      });
    });

    const sortedSchedule = Object.values(schedule).sort((a, b) => {
      return (a.startTime || '').localeCompare(b.startTime || '');
    });

    setParsedData(sortedSchedule);
    setActiveDays(Array.from(activeDaysSet));
  };

  const handleDataInput = (e) => {
    setScheduleData(e.target.value);
  };

  const handleSaveData = () => {
    parseScheduleData(scheduleData);
    setIsScheduleModalOpen(false);
    setShowInstructions(false);
  };

  const handleTitleClick = () => {
    setNewTitle(title);
    setIsTitleModalOpen(true);
  };

  const handleSaveTitle = () => {
    setTitle(newTitle);
    setIsTitleModalOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setOrientation('portrait');
      } else {
        setOrientation('landscape');
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const dayOrder = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday'];

  return (
    <div className="container min-w-full mx-auto pr-4 pl-4">
      {showInstructions ? (
        <WelcomeSection
          onGetStarted={() => setIsScheduleModalOpen(true)}
          onWatchTutorial={() => setIsVideoModalOpen(true)}
        />
      ) : (
        <div ref={tableRef} className="overflow-x-auto mt-5">
          <h1
            id="schedule-title"
            className="text-center text-3xl sm:text-5xl font-bold mb-6 sm:mb-10 text-gray-800 cursor-pointer hover:text-gray-600 transition-colors"
            onClick={handleTitleClick}
          >
            {title}
          </h1>

          {parsedData.length > 0 ? (
            <div id="schedule-table" className={`overflow-x-auto ${selectedPreset.class}`}>
              <table className="min-w-full table-auto">
                {isRowBased ? (
                  <>
                    <thead className={`${selectedColor.class} text-white`}>
                      <tr>
                        <th className="px-2 sm:px-4 py-4 text-left text-xs sm:text-sm font-semibold uppercase tracking-wider border-r border-opacity-20">
                          Time
                        </th>
                        {dayOrder.map((day) => (
                          activeDays.includes(day) && (
                            <th
                              key={day}
                              className="px-2 sm:px-4 py-4 text-left text-xs sm:text-sm font-semibold uppercase tracking-wider border-r border-opacity-20"
                            >
                              {day}
                            </th>
                          )
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {parsedData.map((slot, index) => (
                        <tr key={index} className="hover:bg-opacity-10 hover:bg-gray-300 transition-colors">
                          <td className="border px-2 sm:px-4 py-2 text-xs sm:text-sm font-medium">{slot.time}</td>
                          {dayOrder.map((day) => (
                            activeDays.includes(day) && (
                              <td
                                key={day}
                                className="border px-2 sm:px-4 py-2 text-xs sm:text-sm"
                                dangerouslySetInnerHTML={{ __html: slot[day] }}
                              ></td>
                            )
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </>
                ) : (
                  <>
                    <thead className={`${selectedColor.class} text-white`}>
                      <tr>
                        <th className="px-2 sm:px-4 py-4 text-left text-xs sm:text-sm font-semibold uppercase tracking-wider border-r border-opacity-20">
                          Day
                        </th>
                        {parsedData.map((slot, index) => (
                          <th key={index} className="px-2 sm:px-4 py-4 text-left text-xs sm:text-sm font-semibold uppercase tracking-wider border-r border-opacity-20">
                            {slot.time}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {dayOrder.map((day) => (
                        activeDays.includes(day) && (
                          <tr key={day} className="hover:bg-opacity-10 hover:bg-gray-300 transition-colors">
                            <td className="border px-2 sm:px-4 py-2 text-xs sm:text-sm font-medium">{day}</td>
                            {parsedData.map((slot, index) => (
                              <td
                                key={index}
                                className="border px-2 sm:px-4 py-2 text-xs sm:text-sm"
                                dangerouslySetInnerHTML={{ __html: slot[day] }}
                              ></td>
                            ))}
                          </tr>
                        )
                      ))}
                    </tbody>
                  </>
                )}
              </table>
            </div>
          ) : (
            <div className="text-center text-gray-600 text-lg">No schedule data available. Click "Edit Schedule" to add data.</div>
          )}
        </div>
      )}

      {!showInstructions && (
        <div className="flex flex-wrap justify-center mt-8 gap-4">
          <button
            onClick={() => setIsScheduleModalOpen(true)}
            className="px-6 py-3 bg-[#1f5768] hover:bg-[#18485a] text-white rounded-md transition duration-300 flex items-center justify-center shadow-md"
          >
            <Edit className="mr-2" size={20} />
            Edit Schedule
          </button>
          
          <button
            onClick={() => setIsCustomizeModalOpen(true)}
            className="px-6 py-3 bg-[#614778] hover:bg-[#523565] text-white rounded-md transition duration-300 flex items-center justify-center shadow-md"
          >
            <Settings className="mr-2" size={20} />
            Customize
          </button>
          
          {parsedData.length > 0 && (
            <DownloadAsImage
              tableRef={tableRef}
              selectedPreset={selectedPreset}
            />
          )}
          <button
            onClick={() => setShowInstructions(true)}
            className="px-6 py-3 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-full transition duration-300 flex items-center justify-center shadow-md"
          >
            <RotateCcw className="mr-2" size={20} />
            Back to Instructions
          </button>
        </div>
      )}

      {isScheduleModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-lg">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-gray-900">Schedule Data</h3>
              <button onClick={() => setIsScheduleModalOpen(false)} className="text-gray-400 hover:text-gray-600">
                <X size={24} />
              </button>
            </div>
            <textarea
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-300 focus:border-blue-500 mb-4"
              rows="10"
              value={scheduleData}
              onChange={handleDataInput}
              placeholder="Paste your schedule data here..."
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsScheduleModalOpen(false)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveData}
                className="px-4 py-2 bg-[#1f5768] text-white rounded hover:bg-[#18485a] transition duration-300"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {isTitleModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-gray-900">Edit Title</h3>
              <button onClick={() => setIsTitleModalOpen(false)} className="text-gray-400 hover:text-gray-600">
                <X size={24} />
              </button>
            </div>
            <input
              type="text"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              placeholder="Enter new title"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-300 focus:border-blue-500 mb-4"
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsTitleModalOpen(false)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveTitle}
                className="px-4 py-2 bg-[#1f5768] text-white rounded hover:bg-[#18485a] transition duration-300"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {isVideoModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-4xl">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-gray-900">Tutorial Video</h3>
              <button onClick={() => setIsVideoModalOpen(false)} className="text-gray-400 hover:text-gray-600">
                <X size={24} />
              </button>
            </div>
            <div className="aspect-w-16 aspect-h-9">
              <video
                className="w-full h-full object-cover rounded-lg"
                controls
                crossOrigin="anonymous"
              >
                <source src="/tutorial.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}

      {isCustomizeModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-gray-900">Customize Schedule</h3>
              <button onClick={() => setIsCustomizeModalOpen(false)} className="text-gray-400 hover:text-gray-600">
                <X size={24} />
              </button>
            </div>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Table Style</label>
                <select
                  value={selectedPreset.name}
                  onChange={(e) => setSelectedPreset(tablePresets.find(preset => preset.name === e.target.value))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  {tablePresets.map((preset) => (
                    <option key={preset.name} value={preset.name}>{preset.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Header Color</label>
                <select
                    value={selectedColor.name}
                    onChange={(e) => setSelectedColor(colorOptions.find(color => color.name === e.target.value))}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
                    {colorOptions.map((color) => (
                      <option key={color.name} value={color.name}>{color.name}</option>
                    ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Image Orientation | ⚠️ Coming Soon </label>
                <select
                value={orientation}
                onChange={(e) => setOrientation(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                <option value="portrait">Portrait</option>
                <option value="landscape">Landscape</option>
              </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Layout</label>
                <button
                  onClick={() => setIsRowBased(!isRowBased)}
                  className="w-full px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-md transition duration-300 flex items-center justify-center"
                >
                  {isRowBased ? <Rows className="mr-2" size={20} /> : <Grid className="mr-2" size={20} />}
                  {isRowBased ? 'Switch to Column-based' : 'Switch to Row-based'}
                </button>
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                onClick={() => setIsCustomizeModalOpen(false)}
                className="px-4 py-2 bg-[#1f5768] text-white rounded-md hover:bg-[#18485a] transition duration-300"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
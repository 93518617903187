import { Calendar, Palette, Download, Play } from "lucide-react"
import logo from '../logo512.png';
export default function Component({ onGetStarted, onWatchTutorial }) {
  return (
    <div className="bg-gradient-to-br from-[#e6f3f7] to-[#c5e8f2] min-h-screen flex flex-col justify-center items-center p-4 sm:p-8">
      <div className="max-w-4xl w-full text-center space-y-6 sm:space-y-8">
        <img src={logo} alt="IUB Logo" className="mx-auto mb-4" width={180} height={80} />

        <h1 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-[#1f5768] leading-tight">
          IUB Class Schedule Creator
        </h1>
        <p className="text-lg sm:text-xl text-[#1f5768] max-w-2xl mx-auto">
          Create, customize, and download your class schedule in just a few simple steps.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6">
          <InstructionCard
            icon={Calendar}
            title="Add Classes"
            description="Copy & Paste your class data from IRAS."
          />
          <InstructionCard
            icon={Palette}
            title="Personalize"
            description="Choose layout, colors and styles."
          />
          <InstructionCard
            icon={Download}
            title="Download"
            description="Save as PNG or JPG."
          />
        </div>
        <div className="flex flex-col sm:flex-row justify-center gap-4 mt-6 sm:mt-8">
          <button
            onClick={onGetStarted}
            className="flex items-center justify-center px-6 py-3 bg-[#1f5768] hover:bg-[#16424f] text-white text-base sm:text-lg font-semibold rounded-lg transition duration-300 focus:outline-none focus:ring-2 focus:ring-[#1f5768] focus:ring-offset-2"
          >
            <Calendar className="mr-2" size={20} />
            Get Started
          </button>
          <button
            onClick={onWatchTutorial}
            className="flex items-center justify-center px-6 py-3 bg-white hover:bg-gray-100 text-[#1f5768] text-base sm:text-lg font-semibold rounded-lg transition duration-300 focus:outline-none focus:ring-2 focus:ring-[#1f5768] focus:ring-offset-2"
          >
            <Play className="mr-2" size={20} />
            Watch Tutorial
          </button>
        </div>
      </div>
    </div>
  )
}

function InstructionCard({ icon: Icon, title, description }) {
  return (
    <div className="bg-white bg-opacity-80 backdrop-blur-sm rounded-lg shadow-lg p-4 flex flex-col items-center">
      <Icon className="w-10 h-10 text-[#1f5768] mb-2" />
      <h3 className="text-lg font-semibold text-[#1f5768] mb-1">{title}</h3>
      <p className="text-sm text-center text-[#1f5768]">{description}</p>
    </div>
  )
}
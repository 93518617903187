import React, { useState } from 'react';
import html2canvas from 'html2canvas';
import { Download, ChevronDown } from 'lucide-react';

const DownloadAsImage = ({ tableRef, selectedPreset }) => {
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);

  const downloadAsImage = async (format) => {
    if (tableRef.current) {
      const element = tableRef.current;

      // Store original styles
      const originalStyles = {
        padding: element.style.padding,
        backgroundColor: element.style.backgroundColor,
      };

      // Change title color to white if Dark preset is selected
      const title = element.querySelector('#schedule-title');
      const originalTitleColor = title.style.color;
      if (selectedPreset.name === 'Dark') {
        title.style.color = 'white';
      }

      // Apply temporary styles
      element.style.padding = '20px 60px 180px 60px';
      element.style.backgroundColor = selectedPreset.name === 'Dark' ? '#202020' : 'white';

      // Fixed desktop dimensions
      const fixedWidth = 1920;
      const fixedHeight = element.scrollHeight;
      const scale = 2;

      // Generate the canvas
      const canvas = await html2canvas(element, {
        scale: scale,
        width: fixedWidth,
        height: fixedHeight,
        windowWidth: fixedWidth,
        windowHeight: fixedHeight,
      });

      // Restore original styles
      element.style.padding = originalStyles.padding;
      element.style.backgroundColor = originalStyles.backgroundColor;
      title.style.color = originalTitleColor;

      // Handle the download
      const image = canvas.toDataURL(`image/${format}`);
      const link = document.createElement('a');
      link.href = image;
      link.download = `schedule.${format}`;
      link.click();
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsDownloadOpen(!isDownloadOpen)}
        className="px-6 py-3 bg-[#49abac] hover:bg-[#3e8f90] text-white rounded-md transition duration-300 flex items-center justify-center shadow-md"
      >
        <Download className="mr-2" size={20} />
        Download as Image
        <ChevronDown className="ml-2" size={16} />
      </button>
      {isDownloadOpen && (
        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button
              onClick={() => { downloadAsImage('png'); setIsDownloadOpen(false); }}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
              role="menuitem"
            >
              Download as PNG
            </button>
            <button
              onClick={() => { downloadAsImage('jpeg'); setIsDownloadOpen(false); }}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
              role="menuitem"
            >
              Download as JPG
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadAsImage;